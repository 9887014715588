<template>
  <div>
    <!-- SJISに変換して送信 -->
    <form style="display: none" v-if="sbpsFormData" :action="sbpsFormData.action" method="post" accept-charset="Shift_JIS">
      <input type="submit" ref="sbpsSubmit">
      <v-text-field v-for="(value, key) in sbpsFormData" type="text" :label="key" :name="key" :value="value" :key="key"></v-text-field>
    </form>

    <v-dialog
        v-model="dialog"
        width="450px"
    >
      <v-card>
        <v-card-title class="headline">お支払いの確認</v-card-title>

        <v-card-text v-if="confirmMessages">
          以下の内容で決済を行います。
        </v-card-text>

        <v-card-text v-if="confirmMessages">
          <ul>
            <li v-for="(confirmMessage, i) in confirmMessages" :key="i">
              {{ confirmMessage }}
            </li>
          </ul>
        </v-card-text>

        <!-- プリンタ有り駐輪場の場合のみQRコード警告を表示 -->
        <template v-if="hasPrinter">
          <v-card-text>
            QRコードを読み取れない場合がございます。
          </v-card-text>

          <v-card-text>
            <ul>
              <li>携帯の画面にひび割れはございませんか？</li>
              <li>画面保護フィルムの汚れ、傷はありませんか？</li>
              <li>画面の明るさの設定が暗くなってませんか？</li>
            </ul>
          </v-card-text>

          <v-card-text>
            ※QRコードが読み取れない場合は印刷してご利用いただくかコールセンターまたはお問い合わせ欄よりご連絡ください。
          </v-card-text>
        </template>

        <v-form>
          <form class="form" @submit.prevent="submitClick()">
            <v-card-text class="text-center">
              <v-row>
                <v-spacer></v-spacer>

                <v-checkbox
                    v-model="dialogAgree"
                    name="submit-confirm"
                    label="上記内容を確認しました"
                    required
                >
                </v-checkbox>
                <v-spacer></v-spacer>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                    type="submit"
                    class="mt-2"
                    dark depressed
                    color="primary"
                >
                  決済画面へ進む
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    @click="dialog = false; dialogAgree = false"
                    name="applyApi"
                    class="mt-2"
                    dark depressed
                    color="secondary"
                >
                  戻る
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-text>
          </form>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    'form',
    'confirmMessages',
    'parkingId',
    'parkingZoneId',
    'userParkingZoneApplicationId',
    'hasPrinter',
  ],
  data() {
    return {
      sbpsFormData: undefined,
      dialog: false,
      dialogAgree: false,
    }
  },
  methods: {
    // 決済実行
    async submit(isDebug = false, paymentAction) {
      // パラメータとハッシュ値をAPIから取得
      await this.getForm(isDebug, paymentAction);

      // 確認ダイアログを開く
      this.dialog = true;
    },

    // 決済実行
    async submitClick() {
      // Submitボタンをクリック
      this.$refs["sbpsSubmit"].click();
    },

    // パラメータとハッシュ値をAPIから取得
    async getForm(isDebug = false, paymentAction) {
      const formData = {
        'is_debug': isDebug,
        'payment_action': paymentAction,
        'parking_id': this.parkingId,
        'parking_zone_id': this.parkingZoneId,
        'user_parking_zone_application_id': this.userParkingZoneApplicationId,
        'payment_service_id': this.form.payment_service_id,
        'parking_waiting_unique_key': this.form.parking_waiting_unique_key,
      };

      // フォーム情報を取得
      const response = await this.$axios.post('/api/sbps/form', formData)
          .catch(error => this.$store.state.error.response = error.response);

      this.sbpsFormData = response.data.form;
    },
  }
}
</script>
