<template>
  <v-card flat class="pa-0 ma-0 d-flex">
    <BackButton :backTo="backTo"></BackButton>
    <v-card-title>{{ $route.meta.title }}</v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    // 子コンポーネントから戻りページを指定されていればBackButtonコンポーネントに引き継ぎ
    backTo: undefined,
  },
};
</script>
