const state = {
    to: null
};

const mutations = {
    setTo(state, to) {
        state.to = to
    }
};

export default {
    namespaced: true,
    state,
    mutations
}
