<template>
  <v-icon @click="back" class="display-1">mdi-arrow-left</v-icon>
</template>

<script>
export default {
  props: {
    // 子コンポーネントから戻りページを指定されていれば設定
    backTo: undefined,
  },

  data() {
    return {
      // 戻り先URL
      storeBackTo: undefined,
      // プロパティの値を設定
      backToData: this.backTo
    }
  },


  mounted() {
    // ストアから戻りページ先を取得
    if (this.$store.state.back.to) {
      this.backToData = this.$store.state.back.to;
    }

    // ストアの値をリセット
    this.$store.commit('back/setTo', undefined);
  },

  methods: {
    back() {
      // 指定された戻り先ページへ移動、またはブラウザバックを実行
      if (this.backToData) {
        this.$router.push(this.backToData);
      } else {
        this.$router.back();
      }
    }
  },
};
</script>
