<template>
  <div>
    <!-- 共通試験環境での注意事項を表示 -->
    <template v-if="!isProduction">
      <v-card-text class="text-center red--text text--darken-2">
        <template v-if="paymentService['sbps_pay_method'] === 'credit'">
          試験環境ではテスト用カード情報が<br>
          あらかじめ入力されています。
        </template>
        <template v-else-if="paymentService['sbps_pay_method'] === 'docomo'">
          共通試験環境ではドコモ払いの<br>
          テストを行うことはできません。<br>
          決済画面へ遷移する部分までの確認となります。
          <!--
          共通試験環境でもドコモ払いは実決済となります。<br>
          テスト決済の取消は決済代行会社<br>
          コンソール画面からの操作が必要です。
          -->
        </template>
        <template v-else-if="paymentService['sbps_pay_method'] === 'auone'">
          共通試験環境ではauかんたん決済の<br>
          テストを行うことはできません。<br>
          決済画面へ遷移する部分までの確認となります。
        </template>
        <template v-else-if="paymentService['sbps_pay_method'] === 'softbank2'">
          共通試験環境でのソフトバンクまとめて支払いは<br>
          <a :href="config.const['payment']['sbps']['dummy_softbank_2_account_register_url']" target="_blank">初期設定ページ</a>から利用端末ごとに登録を行い<br>
          下記アカウント情報でログインしてください。<br>
          <br>
          ・クレジットカードでの決済を行う場合<br>
          電話番号:{{ config.const['payment']['sbps']['dummy_softbank2_cc_id'] }}<br>
          パスワード:{{ config.const['payment']['sbps']['dummy_softbank2_cc_pw'] }}<br>
          暗証番号:{{ config.const['payment']['sbps']['dummy_softbank2_cc_pin'] }}<br>
          3Dセキュアパスワード:{{ config.const['payment']['sbps']['dummy_softbank2_cc_3dsecure'] }}<br>
          <br>
          ・口座振込での決済を行う場合<br>
          電話番号:{{ config.const['payment']['sbps']['dummy_softbank2_bt_id'] }}<br>
          パスワード:{{ config.const['payment']['sbps']['dummy_softbank2_bt_pw'] }}<br>
          暗証番号:{{ config.const['payment']['sbps']['dummy_softbank2_bt_pin'] }}
        </template>
        <template v-else-if="paymentService['sbps_pay_method'] === 'webcvs'">
          共通試験環境でのコンビニ決済ではバーコードが表示される払込票の表示まで確認することが出来ますが、実際に店頭で支払うことは出来ません。<br>
          またメールアドレス・電話番号欄の自動入力、払込票への指定した支払期限(翌日24時)の値の反映は行われません。<br>
          <br>
          共通試験環境では以下の手順で店頭での支払いをスキップして以降の手続きを進めることが出来ます。<br>
          <br>
          <ol class="text-left">
            <li>「SBPS接続テスト」ボタンを押し決済テストサイトへ移動</li>
            <li>ページ下部の「決済結果通知処理」ボタンを押して次のページに進む</li>
            <li>ページ下部の「処理結果ステータス」セレクタから「OK■受付完了」を選択</li>
            <li>「決済結果通知処理実行」ボタンを押す。この処理で入金待ちの状態となります。</li>
            <li>ブラウザバックで一つ前のページに戻る。表示されない場合は再読み込みを行う。</li>
            <li>再び「決済結果通知処理実行」ボタンを押す。この処理で入金が完了した状態となります。</li>
            <li>決済の完了によりサイクルプラザから利用者に入金通知メールを送信</li>
            <li>メール文中のURLより更新されたQRコードが表示されるページへ移動</li>
          </ol>
        </template>
      </v-card-text>
      <v-divider></v-divider>
    </template>
  </div>
</template>

<script>
export default {
  props: [
    'config',
    'paymentService',
  ],
  computed: {
    // 本番環境かどうか
    isProduction: function () {
      return !!this.config && (this.config.app.env === 'production');
    },
  },
}
</script>
