const state = {
    response: null
};

const mutations = {
    setCode(state, response) {
        state.response = response
    }
};

export default {
    namespaced: true,
    state,
    mutations
}
