<template>
  <span style="white-space: pre-line">{{ text }}</span>
</template>

<script>
export default {
  data() {
    return {
      text: '定期利用ご利用規約\n' +
          '\n' +
          '1. 当駐輪場は自転車、バイク以外のご利用はできません。\n' +
          '2. 当駐輪場は秩序維持のため、予め主たる車輌のご登録を頂きます。登録車輌およびその他登録内容を変更される際は、WEBもしくは下記問い合わせ先にて、変更の申請をお願い致します。\n' +
          '3. 駐輪される場所によっては、車高や車重、車輪や車枠のサイズ等に制限のある場合があります。なお、ご契約頂いた後であっても、当社で規格外と判断させて頂いた車輌の利用はお断りさせて頂く場合があります。\n' +
          '4. 定期利用料（新規は利用開始日までに、継続は終了月の16日までに）お申込み時に選択した方法によりお支払頂きます。お支払方法を変更する場合は、ご利用終了月の15日までにWEBサイトマイページより変更してください。\n' +
          '5. お支払いは初回以降自動更新決済となりますので、ご利用終了月の16日に自動決済されます。\n' +
          '6. 定期利用シールは、利用現場のシール発行機もしくは郵送にて発行いたします。\n' +
          '7. ご利用の際は、必ず有効期間内の定期利用シールを後輪泥除け部（無い場合は、車輌後部の見易い位置）へ貼付し、指定駐輪場所へ駐輪してください。\n' +
          '8. 申請者様の氏名とクレジットカードの名義人様が一致しない場合は、申請者様はクレジットカード名義人様から利用の承諾を得ているものとみなします。\n' +
          '9. 支払期限を過ぎても定期利用料のお支払が確認できない場合は、継続してご利用頂くことが出来なくなります。\n' +
          '10. 契約場所変更を希望される場合は、新たにお申し込み、ご契約頂くことが必要となります。\n' +
          '11. 契約期間中に解約される場合の返金はいたしかねます。但し、お支払いをしております利用期間内のご利用は可能です。\n' +
          '12. 定期利用シールを紛失、破損した場合は、WEBサイトのマイページより再発行手続きを行って下さい。再発行する場合は、再発行手数料として1,000円を頂きます。\n' +
          '13. 当社からのご連絡、ご案内をご登録頂いたメールアドレス宛に送信させて頂くことがあります。URL付メールおよびドメイン[@cycle.plazanet.jp]が受信できるように設定をしてください。\n' +
          '14. 駐輪場内は車輌から降りてご通行ください。\n' +
          '15. 駐輪場利用者は、利用に際し、以下の行為（そのおそれのある行為を含みます）を行わないものとします。\n' +
          '・他の利用者その他の第三者の権利、利益を侵害する行為\n' +
          '・駐輪場内で公序良俗、その他の法令もしくは刑事法規に違反、または当社が不適切と判断する行為\n' +
          '16. 契約期間を過ぎて駐輪されている車輌、定期利用シールの貼付が無い車輌は、当方にて撤去、処分いたします。\n' +
          '17. 当駐輪場は駐輪場所を提供するもので、ご利用者様の車輌をお預りする保管施設ではありません。施設内での盗難・紛失・破損・事故等についての責任は一切負いません。\n' +
          '18. ご利用者様における使用上の誤り、あるいは登録車輌以外を使用したことによる破損等、ご利用者様自身の故意または過失に基づく駐輪場の施設、機器の破損が発生した場合、損害はすべてご利用者様自身のご負担となります。\n' +
          '19. 暴力団関係者等反社会的勢力のご利用をお断りします。\n' +
          '20. 本規約は、必要に応じ変更する事があります。\n' +
          '\n' +
          '利用規約を必ずお読み頂き、規約に同意のうえで、申し込みをお願い致します。\n' +
          '申請者と利用者が異なる場合も、申請者の同意を以て利用者の同意を得たものと見なします。\n' +
          '\n' +
          '個人情報の取扱について：\n' +
          'ご入力頂いた氏名などの個人情報は、駐輪場管理・運営に利用し、当社の責任において厳正かつ安全に保管・管理いたします。なお、取得した個人情報の取扱いの全部又は一部を必要な保護措置を講じた上、駐輪場の管理・運用の目的達成に必要な範囲においてのみ、外部業者に委託する場合がございます。その場合には、当社は当該外部業者と個人情報の取扱いに関する契約を締結しお客さまの個人情報の管理を徹底いたします。\n' +
          '\n' +
          'アグナス株式会社　個人情報保護管理者\n' +
          '\n' +
          '個人情報の開示・訂正・利用停止等の請求、および各種お問い合わせは、下記の連絡先までご連絡ください。\n' +
          '\n' +
          '(お問合せ先) アグナス株式会社\n' +
          '〒101-0024 東京都千代田区神田和泉町1-4-7 早尾ビル3F\n' +
          'contact@cycle.plazanet.jp'
    };
  },
};
</script>
