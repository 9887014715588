import axios from 'axios'
import * as Sentry from "@sentry/vue";

// 環境設定からベースURLを設定
axios.defaults.baseURL = process.env.BASE_URL ? process.env.BASE_URL : '';

const state = {
    user: null
};

const getters = {
    check: state => !!state.user,
    user: state => state.user,
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    }
};

const actions = {
    /**
     * 利用者登録
     *
     * @param context
     * @return {Promise<void>}
     */
    async register(context) {
        const response = axios.post('/api/user/register'.data)
            .catch(error => this.$store.state.error.response = error.response);

        context.commit('setUser', response.data);
    },

    /**
     * ログイン
     *
     * @param context
     * @param data
     * @return {Promise<void>}
     */
    async login(context, data) {
        const response = await axios.post('/api/user/login', data)
            .catch((error) => {
                // エラーがあればrejectしてハンドリンクは呼び出し元で行う
                return Promise.reject(error);
            });

        context.commit('setUser', response.data);

        // Sentryにユーザ情報を設定
        Sentry.configureScope((scope) => {
            scope.setUser(response.data);
        });
    },

    /**
     * ログアウト
     *
     * @param context
     * @return {Promise<void>}
     */
    async logout(context) {
        await axios.post('/api/user/logout')
            .catch(error => this.$store.state.error.response = error.response);

        context.commit('setUser', null);

        // Sentryのユーザ情報を削除
        Sentry.configureScope((scope) => {
            scope.clear()
        });
    },

    /**
     * 利用者情報取得
     *
     * @param context
     * @return {Promise<void>}
     */
    async currentUser(context) {
        const response = await axios.get('/api/user')
            .catch(error => this.$store.state.error.response = error.response);

        context.commit('setUser', response.data);

        // Sentryにユーザ情報を設定
        Sentry.configureScope((scope) => {
            scope.setUser(response.data);
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
