<template>
  <div>
    <!-- SJISに変換して送信 -->
    <form v-if="sbpsFormData" :action="sbpsFormData.action" method="post" accept-charset="Shift_JIS">
      <input type="submit" ref="sbpsSubmit">
      <v-text-field v-for="(value, key) in sbpsFormData" type="text" :label="key" :name="key" :value="value" :key="key"></v-text-field>
    </form>
  </div>
</template>

<style scoped>
form {
  display: none;
}
</style>

<script>
export default {
  props: [
    'form',
    'onetimePaymentApplicationId',
  ],
  data() {
    return {
      sbpsFormData: undefined,
    }
  },
  methods: {
    // 決済実行
    async submit(isDebug = false, paymentAction) {
      // パラメータとハッシュ値をAPIから取得
      await this.getForm(isDebug, paymentAction);

      // Submitボタンをクリック
      this.$refs["sbpsSubmit"].click();
    },

    // パラメータとハッシュ値をAPIから取得
    async getForm(isDebug = false, paymentAction) {
      const formData = {
        'is_debug': isDebug,
        'payment_action': paymentAction,
        'onetime_payment_application_id': this.onetimePaymentApplicationId,
        'payment_service_id': this.form.payment_service_id,
      };

      // フォーム情報を取得
      const response = await this.$axios.post('/api/sbps/form-onetime-payment', formData)
          .catch(error => this.$store.state.error.response = error.response);

      this.sbpsFormData = response.data.form;
    },
  }
}
</script>
