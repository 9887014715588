import Vue from 'vue';
import Vuetify, {VSnackbar} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import VuetifyToast from 'vuetify-toast-snackbar';

Vue.use(Vuetify, {
    components: {
        VSnackbar,
    },
});

const opts = {
    theme: {
        themes: {
            light: {
                warning: colors.yellow.lighten1,
            },
        },
    },
};

const vueObj = new Vuetify(opts);

export default vueObj;

Vue.use(VuetifyToast, {
    $vuetify: vueObj.framework,
    x: 'center',
    y: 'top',
    // color: 'info', // default
    // icon: 'info',
    // iconColor: '', // default
    // classes: [
    //     'body-2'
    // ],
    // timeout: 3000, // default
    // dismissable: true, // default
    // multiLine: false, // default
    // vertical: false, // default
    // queueable: false, // default
    // showClose: false, // default
    // closeText: '', // default
    // closeIcon: 'close', // default
    // closeColor: '', // default
    // slot: [], //default
    // shorts: {
    //     custom: {
    //         color: 'purple'
    //     }
    // },
    // property: '$toast' // default
})