import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import auth from './auth.js';
import back from './back';
import error from './error.js';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        back,
        error,
    },
    plugins: [createPersistedState({storage: window.sessionStorage})],
})
