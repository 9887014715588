<template>
  <span style="white-space: pre-line">{{ text }}</span>
</template>

<script>
export default {
  data() {
    return {
      text: '個人情報保護に関する当社の方針\n' +
          '\n' +
          '当社は、個人情報の重要性を十分認識し、個人情報保護のために以下のとおり「個人情報保護方針」を定め、役員および従業員に周知徹底するとともに、個人情報の適切な取扱い、管理に努めます。\n' +
          '\n' +
          '個人情報保護方針\n' +
          '\n' +
          '1. 当社は、個人情報の取得、利用および提供に際し、当社の正当な事業活動の範囲内でこれを行います。あらかじめ特定した利用目的の範囲を超えて個人情報を取扱う場合は、法令等に定める場合を除き、ご本人の同意を得ることといたします。\n' +
          '2. 当社は、個人情報の正確性および安全性を確保するための安全対策を実施し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏洩等の予防並びに是正に努めます。\n' +
          '3. 当社は、個人情報の取扱いにおいて適用される法令およびその他の規範を遵守します。\n' +
          '4. 当社は、個人情報保護マネジメントシステムを確立、実施、維持し、かつ社会情勢・環境の変化を踏まえた継続的改善を行います。\n' +
          '5. 当社は、個人情報の取扱いに関する苦情および相談に対し、その内容について迅速に事実関係を調査し、誠意をもって対応いたします。\n' +
          '\n' +
          '個人情報の取扱いについて\n' +
          '\n' +
          '当社は、個人情報保護の重要性を十分認識し、「個人情報保護方針」に従い、以下に記載のとおり個人情報を適切に取扱います。\n' +
          '\n' +
          '1. 個人情報取扱事業者の名称\n' +
          'アグナス株式会社\n' +
          '\n' +
          '2. 個人情報の管理体制\n' +
          '当社は個人情報の取扱いに関する社内規程を定め、個人情報取扱いの総責任者として個人情報保護管理者を指名、ならびに各部門個人情報責任者を選任し、個人情報保護のための管理体制を整備しております。\n' +
          '\n' +
          '3. 個人情報の利用目的\n' +
          '当社は、お客様等の個人情報を以下の利用目的で利用させていただき、法令に定める特別な場合を除いて、ご本人の同意なく利用目的の範囲を超えた利用はいたしません。\n' +
          '\n' +
          '(1) お客様に関する個人情報\n' +
          '① 商品、サービス、催し物のご案内の送付\n' +
          '② 商品、サービスのサポート、メンテナンスの提供\n' +
          '③ お問い合わせ、ご相談への対応\n' +
          '④ 商品開発、アンケート調査実施、モニター実施\n' +
          '⑤ 施設、設備および機器の保守管理並びにその利用状況の管理\n' +
          '⑥ 契約の履行\n' +
          '⑦ お客様との商談、打合せ等\n' +
          '\n' +
          '(2) 取引先各社、他社の役員、社員等に関する個人情報\n' +
          '① 業務上必要な諸連絡、商談等\n' +
          '② 取引先情報管理、支払、収入処理\n' +
          '\n' +
          '(3) 人事・採用関連、従業者（退職者含む）に関する個人情報\n' +
          '① 採用応募者への採用情報等の提供、連絡\n' +
          '② 労務管理、人事管理、健康管理、業務管理、セキュリティ管理\n' +
          '③ 互助組織・グループ会社への連絡、提供\n' +
          '④ 官公庁への届け出、報告\n' +
          '⑤ 労務関連法令への対応、連絡\n' +
          '⑥ 連絡文書の送付\n' +
          '\n' +
          '(4) 他の事業者等から処理を委託された個人情報\n' +
          '他の事業者等から個人情報の処理の全部または⼀部について委託された場合において、委託された当該業務を適切に行うため、当社が個人情報を利用する場合の主たる事業領域は、以下のとおりです。\n' +
          '① 自転車駐車場の経営並びに設備機器の開発、販売、運用\n' +
          '\n' +
          '(5) お問い合わせフォームから取得した個人情報\n' +
          '① お問い合わせの受付・回答\n' +
          '\n' +
          '4. 個人情報の取得・利用\n' +
          '当社は、個人情報の取得・利用においては、利用目的を明確にするとともに法令を遵守して行います。また、法令に定める特別な場合を除いて、ご本人の同意なく利用目的の範囲を超えて利用いたしません。\n' +
          '\n' +
          '5. 個人情報の第三者への開示・提供\n' +
          '当社は、お客様の個人情報を法令に定める特別な場合を除いて、ご本人の同意なく第三者へ提供いたしません。\n' +
          '\n' +
          '6. 個人情報の安全管理\n' +
          '当社は、全従業者に個人情報保護教育を実施し、個人情報保護の重要性を周知するとともに、「機密事項並びに個人情報保持誓約書」の提出を義務付けています。また、取得した個人情報の漏洩、滅失または毀損の防止、その他個人情報の安全管理のために必要かつ適切な措置を講じ、これらの定期点検を行います。\n' +
          '\n' +
          '7. 個人情報の取扱いの委託\n' +
          '当社は、取得した個人情報を上記利用目的の遂行上、社外に委託する場合、当社が定めた水準を満たす事業者等を委託先とし、個人情報の取扱いについて適切な指導・監督を行います。\n' +
          '\n' +
          '8. 個人情報の開示・訂正・利用停止等の請求、および各種お問い合わせ窓口\n' +
          '当社は、ご本人または代理人から個人情報に関する開示・訂正・利用停止等のご請求があった場合、速やかに対応いたします。\n' +
          '\n' +
          'また、その他個人情報取扱いに関するご質問、苦情およびご相談等に関しましては、下記のお問い合わせ窓口にて随時（電話受付は営業時間内に限定）受付けております。\n' +
          '\n' +
          'アグナス株式会社\n' +
          '〒101-0024 東京都千代田区神田和泉町1-4-7 早尾ビル3F\n' +
          'contact@cycle.plazanet.jp'
    };
  },
};
</script>
