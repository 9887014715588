<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <router-link v-if="config" :to="{name:'Index'}" style="color: inherit; text-decoration: none;">
        <v-toolbar-title>
          {{ config.app.name }}
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <!--
      <v-btn @click.prevent="logout" v-if="isLogin" text>
          <v-icon>mdi-logout</v-icon>
          ログアウト
      </v-btn>
      -->
    </v-app-bar>

    <v-main>
      <v-container style="max-width:600px">
        <keep-alive :include="/Map/">
          <router-view :config="config" :testNow="testNow"/>
        </keep-alive>
      </v-container>
    </v-main>

    <v-footer v-if="config" color="primary" dark>
      <v-row>
        <v-col v-if="testNow" cols="12" class="text-center pa-1 mt-3">
          <span class="small">{{ testNow }}</span>
        </v-col>

        <v-col cols="12" class="text-center pa-1 mt-3">
          <v-btn dark depressed small color="primary" :to="{name:'CommercialTransactions'}">特定商取引法に基づく表記</v-btn>
        </v-col>

        <v-col cols="12" class="text-center pa-1">
          <v-btn dark depressed small color="primary" :to="{name:'Policy'}">個人情報の取り扱いについて</v-btn>
        </v-col>

        <v-col cols="12" class="text-center pa-1">
          <v-btn dark depressed small color="primary" href="http://www.agnus.co.jp/">運営会社</v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<style>
.v-card__title,
.theme--light.v-sheet,
.theme--light.v-application {
  color: rgba(0, 0, 0, 0.6) !important;
}

.row {
  margin-top: 0;
  margin-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>

<script>
export default {
  data() {
    return {
      APP_NAME: document.title,
      // 設定情報を取得
      config: undefined,
      // 実時間より速い速度でシステム時間をすすめるテスト日時
      testNow: undefined,
      testNowIntervalId: undefined,
      loading: false,
    }
  },
  mounted() {
    // 設定情報を取得
    this.getConfig();

    // 実時間より速い速度でシステム時間をすすめるテスト日時を取得
    this.getTestNow();
  },
  computed: {
    isLogin() {
      return this.$store.getters['auth/check'];
    },
    username() {
      return "username";
      // return this.$store.getters['auth/username'];
    },
    // axiosのエラーレスポンスを監視する
    errorResponse() {
      return this.$store.state.error.response;
    },
  },
  methods: {
    async logout() {
      // await this.$store.dispatch('auth/logout');

      // this.$router.push('/nav/login');
    },

    // 設定情報を取得
    async getConfig() {
      const response = await this.$axios.get('/api/config')
          .catch(error => this.$store.state.error.response = error.response);

      this.config = response.data.config;
    },

    // 実時間より速い速度でシステム時間をすすめるテスト日時を取得
    async getTestNow() {
      const response = await this.$axios.get('/api/test-now')
          .catch(error => this.$store.state.error.response = error.response);

      const testNow = response.data;

      if (testNow.enabled) {
        // 固定日時でなく時間未取得であれば
        if (testNow.faster_times !== 0 && !this.testNow) {
          this.testNowIntervalId = setInterval(() => {
            // 指定日時から現在までの秒数を取得
            const startDay = this.$dayjs(testNow.base_date);
            const now = this.$dayjs();
            const diffSeconds = now.diff(startDay, 'seconds');
            // 指定日時から倍数をかけたスピードで時間を経過させる
            this.testNow = this.$dayjs(testNow.base_date).add(diffSeconds * testNow.faster_times, 's').format('YYYY/MM/DD HH:mm');
          }, 1000);
        }

        // 固定日時であればAPIで指定された日時を設定
        if (testNow.faster_times === 0) {
          this.testNow = this.$dayjs(testNow.base_date).format('YYYY/MM/DD HH:mm');
        }
      }
    }
  },
  watch: {
    // ルートが変更されたらテスト日時を再取得
    $route() {
      if (this.testNow) {
        this.getTestNow();
      }
    },
    // axiosのエラーレスポンスが変更されたらステータスコードを判断して処理を行う
    errorResponse: {
      async handler(response) {
        // リクエストのキャンセル等でレスポンスが空ならスキップ
        if(typeof response === "undefined") {
          return;
        }

        switch (response.status) {
          case 401: // UNAUTHORIZED
          case 419: // PAGE_EXPIRED
            // 適当なAPIに接続してCSRFトークンリフレッシュ
            await this.$axios.get('/api/parkings');

            // ユーザ情報をクリア
            this.$store.commit('auth/setUser', null);

            // ログイン画面を表示
            await this.$router.replace({
              path: '/login',
              query: {to: this.$route.fullPath}
            });

            break;
            // case 404: // NOT_FOUND
            //     await this.$router.push('/admin/not-found')
            //     break;
            // case 422: // UNPROCESSABLE_ENTITY(バリデーションエラー)
            //     break;
            // case 500: // INTERNAL_SERVER_ERROR
            //     await this.$router.push('/admin/500')
            //     break;
          case 503: // SERVICE_UNAVAILABLE
            // メンテナンス画面を表示
            await this.$router.push({name: 'Maintenance'});

            break;
          default:
            if (response.data.message) {
              // Sentryにエラーを送信
              this.$sentry.captureMessage(response.data);

              alert(response.data.message);
            } else {
              // Sentryにエラーを送信
              this.$sentry.captureMessage(response.status);

              alert('undefined http status code: ' + response.status);
            }
        }
      }
    },
  },
}
</script>

